import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

export const loadingManager = new THREE.LoadingManager();
export const loadScene = new Promise((resolve, reject) => {
    loadingManager.onLoad = () => {
        resolve('result');
    };
});

export const textureLoader = new THREE.TextureLoader(loadingManager);
export const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);
export const svgLoader = new SVGLoader(loadingManager);

export const gltfLoader = new GLTFLoader(loadingManager);
// export const dracoLoader = new DRACOLoader();
// dracoLoader.setDecoderPath('/draco/');
// gltfLoader.setDRACOLoader(dracoLoader);
