import * as THREE from 'three';
// import * as Stats from 'stats.js';
import { Scene } from './scene';
import { canvasBG, animateBackground } from './background';
import { GLOBALS } from '../../ts/globals';



/**
 * Debug
 */
// const stats = new Stats();
// stats.showPanel(0);
// document.body.appendChild(stats.dom);



/**
 * Init
 */
const scenes = [];
const multiCanvases = [];

document.querySelectorAll('.js-canvas-coin').forEach((el, index) => {
    if (GLOBALS.mobile) {
        const container = el.closest('.js-canvas-coin-container');
        const url = container.dataset.img;

        if (url) {
            container.style.backgroundImage = `url(${url})`;
        } else if (!el.closest('.js-multi-container')) {
            scenes[index] = new Scene(el, index);
        }
    } else {
        scenes[index] = new Scene(el, index);
    }
});


// Animated background
animateBackground();



/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
    // FPS meter
    // stats.begin();

    // Time counter
    const elapsedTime = clock.getElapsedTime();

    // Animate scenes

    for (let i = 0; i < scenes.length; i++) {
        scenes[i].tick(elapsedTime);
    }

    for (let i = 0; i < multiCanvases.length; i++) {
        multiCanvases[i].render(elapsedTime);
    }

    // Animate background
    if (canvasBG) {
        animateBackground();
    }

    window.requestAnimationFrame(tick);

    // FPS meter
    // stats.end();
}

tick();
