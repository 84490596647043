import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { gui } from './gui';
import { Coin } from './Coin';
import { clamp } from 'three/src/math/MathUtils';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);



export class Scene {
    constructor(el, index) {
        this.canvas = el;
        this.container = this.canvas.closest('.js-canvas-coin-container');
        this.name = this.canvas.dataset.name;
        this.index = index;

        this.scene = new THREE.Scene();
        this.clock = new THREE.Clock();
        this.sizes = {};
        this.current = {
            position: {
                x: 0,
                y: 0,
                z: 0
            },
            angleCountX: 0,
            angleCountY: 0
        };

        this.localTime = 0;
        this.elapsedTimeOld = 0;
        this.isHovering = false;
        this.isRotating = false;

        this.mouse = {
            deltaX: 0,
            deltaY: 0,
            enterX: null,
            enterY: null,

            xSumm: 0,
            xOld: 0,
            ySumm: 0,
            yOld: 0
        };

        this.updateCanvasSize();
        this.initCamera();
        this.initRenderer();
        // this.initControls();
        this.addObjects();
        if (this.name === 'tbcd') {
            this.addLight();
        }
        this.handleResize();
        // this.addHelpers();
        this.handleMouseMove();
        this.autoRotate();
    }


    initCamera() {
        this.camera = new THREE.PerspectiveCamera(
            20,
            this.sizes.width / this.sizes.height,
            0.1,
            100);

        this.camera.position.set(0, 0, 10);
        this.scene.add(this.camera);
    }


    initRenderer() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: window.devicePixelRatio > 1.3 ? false : true,
            alpha: true
        });
        this.renderer.physicallyCorrectLights = true;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        // this.renderer.toneMapping = THREE.ReinhardToneMapping;
        // this.renderer.toneMappingExposure = 2;
        this.renderer.setClearColor(0xffffff, 0);
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }


    initControls() {
        this.controls = new OrbitControls(this.camera, this.canvas);
        this.controls.enableDamping = true;
    }


    updateParams() {
        this.updateCanvasSize();

        this.camera.aspect = this.sizes.width / this.sizes.height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    }


    handleResize() {
        window.addEventListener('resize', () => {
            this.updateParams();
        });
    }


    updateCanvasSize() {
        const w = window.innerWidth;

        if (w < 640) {
            this.sizes.width = this.sizes.height = 350;
        } else if (w >= 640 && w < 768) {
            this.sizes.width = this.sizes.height = 350;
        } else if (w >= 768 && w < 1000) {
            this.sizes.width = this.sizes.height = 270;
        } else if (w >= 1000 && w < 1200) {
            this.sizes.width = this.sizes.height = 320;
        } else if (w >= 1200 && w < 1400) {
            this.sizes.width = this.sizes.height = 350;
        } else if (w >= 1400) {
            this.sizes.width = this.sizes.height = 420;
        }

        this.container.style.width = this.sizes.width + 'px';
        this.container.style.height = this.sizes.height + 'px';
    }


    addObjects() {
        this.coinWrapPosition = new THREE.Group();
        this.coinWrapRotation2 = new THREE.Group();
        this.coinWrapRotation = new THREE.Group();
        this.coinWrapRotation2.add(this.coinWrapRotation);
        this.coinWrapPosition.add(this.coinWrapRotation2);
        this.scene.add(this.coinWrapPosition);

        this.coin = new Coin(this.name).group;
        this.coin.rotation.x = Math.PI * 0.5;

        this.coinWrapRotation.add(this.coin);
    }


    addLight() {
        // const light = new THREE.PointLight(0xffffff, 10);
        const light = new THREE.DirectionalLight(0xffffff, 2);
        const ambientLight = new THREE.AmbientLight(0xffffff, 2.5);

        light.position.x = 5;
        light.position.y = 3;
        light.position.z = 3;

        this.scene.add(light, ambientLight);
    }


    tick(elapsedTime) {
        // randomize
        elapsedTime += this.index * 3.5;

        // if (!this.isHovering) {
            this.localTime += (elapsedTime - this.elapsedTimeOld);
        // }

        this.elapsedTimeOld = elapsedTime;

        // this.calculateAngle();


        // Update controls
        if (this.controls) {
            this.controls.update();
        }

        // Render
        if (this.renderer) {
            this.renderer.render(this.scene, this.camera);
        }

        // Coin
        if (this.coin) {
            // Coin auto rotation and full rotations
            this.coin.rotation.z = Math.PI * this.current.angleCountX;
            this.coin.rotation.x = Math.PI * (this.current.angleCountY + 0.5);

            // Auto swinging
            this.coinWrapRotation.position.y = Math.sin(elapsedTime * 0.5) * 0.1;
            this.coinWrapRotation.position.z = Math.sin(elapsedTime * 0.75) * 0.2;

            // Auto small rotation
            this.coinWrapRotation.rotation.x = Math.PI * Math.sin(elapsedTime * 0.3) * 0.1;
            this.coinWrapRotation.rotation.y = Math.PI * Math.sin(elapsedTime * 0.4) * 0.1;
            this.coinWrapRotation.rotation.z = Math.PI * Math.sin(elapsedTime * 0.5) * 0.1;

            // Hover position
            this.coinWrapPosition.position.x = clamp(this.mouse.deltaX * 1.7 * 0.001, -0.7, 0.7);
            this.coinWrapPosition.position.y = clamp(this.mouse.deltaY * 1.7 * -0.001, -0.7, 0.7);

            // Hover small rotation
            this.coinWrapRotation2.rotation.x = this.mouse.deltaY * 2 * 0.001;
            this.coinWrapRotation2.rotation.y = this.mouse.deltaX * 2 * 0.001;
        }
    }


    addHelpers() {
        this.scene.add(new THREE.AxesHelper(2));
    }


    handleMouseMove() {
        this.container.addEventListener('mousemove', e => {
            const x = e.offsetX;
            const y = e.offsetY;

            // Rotate horizontally
            const diffX = x - this.mouse.xOld;
            this.mouse.xOld = x;
            this.mouse.xSumm += Math.abs(diffX);

            let speedX = clamp(Math.floor(Math.abs(diffX * 0.2)), 1, 2);

            if (this.mouse.xSumm > 20) {
                this.mouse.xSumm = 0;
                const valX = diffX > 0 ? '-=' + speedX : '+=' + speedX;
                this.rotateX(valX, speedX);
            }


            // Rotate vertically
            const diffY = y - this.mouse.yOld;
            this.mouse.yOld = y;
            this.mouse.ySumm += Math.abs(diffY);

            let speedY = clamp(Math.floor(Math.abs(diffY * 0.2)), 1, 2);
            // Only even to prevent upside-down
            speedY = 2 * Math.round(speedY / 2);

            if (this.mouse.ySumm > 20) {
                this.mouse.ySumm = 0;
                const valY = diffY > 0 ? '+=' + speedY : '-=' + speedY;
                this.rotateY(valY, speedY);
            }


            gsap.to(this.mouse, {
                duration: 0.5,
                deltaX: x - this.mouse.enterX,
                deltaY: y - this.mouse.enterY,
                ease: 'power4.easeInOut'
            });
        });

        this.container.addEventListener('mouseenter', e => {
            this.mouse.enterX = e.offsetX;
            this.mouse.enterY = e.offsetY;
            this.isHovering = true;

            this.mouse.countX = 0;
            this.mouse.xOld = e.offsetX;
            this.mouse.yOld = e.offsetY;
        });

        this.container.addEventListener('mouseleave', () => {
            this.isHovering = false;

            gsap.to(this.mouse, {
                duration: 0.5,
                deltaX: 0,
                deltaY: 0,
                ease: 'back.out(1.7)'
            });
        });
    }



    rotateX(val = '+=2', speed = 1) {
        if (this.isRotating) {
            return;
        }

        // console.log(Math.abs(this.current.angleCountY % 2));

        this.isRotating = true;

        gsap.to(this.current, {
            duration: clamp(1 / speed, 0.9, 2),
            angleCountX: val,
            ease: CustomEase.create("custom", "M0,0,C0.2,0,0.361,0.754,0.494,0.96,0.639,1.186,0.822,1,1,1"),
            onComplete: () => {
                this.isRotating = false;
            }
        });
    }


    rotateY(val = '+=2', speed = 1) {
        if (this.isRotating) {
            return;
        }

        this.isRotating = true;

        gsap.to(this.current, {
            duration: clamp(1 / speed, 0.9, 2),
            angleCountY: val,
            ease: CustomEase.create("custom", "M0,0,C0.2,0,0.361,0.754,0.494,0.96,0.639,1.186,0.822,1,1,1"),
            onComplete: () => {
                this.isRotating = false;
            }
        });
    }


    autoRotate() {
        setTimeout(() => {
            this.autoRotate();

            if (!this.isRotating && !this.isHovering) {
                this.isRotating = true;

                gsap.to(this.current, {
                    duration: 2,
                    angleCountX: '+=1',
                    ease: CustomEase.create("custom", "M0,0,C0.306,0.08,0.298,1,1,1"),
                    onComplete: () => {
                        this.isRotating = false;
                    }
                });
            }
        }, 4000 + Math.random() * 6000);
    }
}
