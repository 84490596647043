window.showSubscribeResult = (result) => {
    window.dispatchEvent(new CustomEvent('subscribed', {
        detail: result
    }));
};

class SubscribeForm {

    constructor(el) {
        this.form = el;
        this.responseContainer = this.form.querySelector('.js-subscribe-response');
        this.inputEmail = this.form.querySelector('[type="email"]');
        this.submitButton = this.form.querySelector('.js-subscribe-submit');
        this.handleSubmit();
        this.handleResponse();
        this.handleInput();
        this.handleResponseClick();
    }


    handleSubmit() {
        this.form.addEventListener('submit', e => {
            e.preventDefault();
            this.send();
        });
    }


    handleInput() {
        this.inputEmail.addEventListener('keyup', () => {
            this.clearStatus();
        });
    }


    send() {
        const action = this.form.dataset.url.replace('/post?u=', '/post-json?u=');
        const params = this.serialize();
        const url = action + params + '&c=showSubscribeResult';

        this.submitButton.disabled = true;
        this.clearStatus();

        $.ajax({
            url: url,
            dataType: "jsonp",
            success: () => {
                this.submitButton.disabled = false;
            },
            error: () => {
                this.submitButton.disabled = false;
            }
        });
    }


    serialize() {
        let str = '';

        this.form.querySelectorAll('input[name]').forEach(input => {
            str += `&${(input).name}=${(input).value}`;
        });

        return str;
    }


    handleResponse() {
        window.addEventListener('subscribed', e => {
            this.submitButton.disabled = false;

            const response = (e).detail || {
                result: 'error',
                msg: 'Something went wrong'
            };

            if (response.result === 'success') {
                this.responseContainer.querySelector('.alert[data-result="success"]').classList.remove('invisible');
                this.responseContainer.querySelector('.alert[data-result="success"]').classList.add('show');
                this.form.reset();
            } else {
                this.responseContainer.querySelector('.alert[data-result="error"]').classList.remove('invisible');
                this.responseContainer.querySelector('.alert[data-result="error"]').classList.add('show');
            }

            setTimeout(() => {
                this.clearStatus();
            }, 7000);
        });
    }


    clearStatus() {
        this.responseContainer.querySelectorAll('.alert[data-result]').forEach(el => {
            el.classList.remove('show');
            el.classList.add('invisible');
        });
    }


    handleResponseClick() {
        this.responseContainer.addEventListener('click', () => {
            this.clearStatus();
        });
    }
}

document.querySelectorAll('.js-subscribe-form form').forEach(el => {
    new SubscribeForm(el);
});
