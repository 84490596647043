// Hover dropdown
document.querySelectorAll('.js-hover-dropdown').forEach(container => {
    container.querySelectorAll('.js-hover-dropdown-btn').forEach(btn => {
        btn.addEventListener('click', () => {
            if (document.querySelector('html.mobile')) {
                container.classList.toggle('_is-active');
            }
        })
    });
});

document.addEventListener('click', e => {
    if (document.querySelector('html.mobile') &&
        !e.target.closest('.js-hover-dropdown')) {
        document.querySelectorAll('.js-hover-dropdown').forEach(dd => {
            dd.classList.remove('_is-active');
        });
    }
});
