import * as THREE from 'three';
import { textureLoader, gltfLoader } from './loadingManager';

import urlModelBase from '../../models/coin_base.glb';
import urlModelXor from '../../models/xor.glb';
import urlModelVal from '../../models/val.glb';
import urlModelPswap from '../../models/pswap.glb';
import urlModelCeres from '../../models/ceres.glb';
import urlModelDeo from '../../models/deo.glb';
// import urlModelXstusd from '../../models/xstusd.glb';
import urlModelXst from '../../models/xst.glb';
// import urlModelTbcd from '../../models/tbcd.glb';

import urlMatXor from '../../img/matcaps/04.png';
import urlMatVal from '../../img/matcaps/05.png';
import urlMatPswap from '../../img/matcaps/03.png';
import urlMatCeres from '../../img/matcaps/02.png';
import urlMatDeo from '../../img/matcaps/01.png';
import urlMatXstusd from '../../img/matcaps/06.png';
import urlMatXst from '../../img/matcaps/07.png';
import urlMatTbcd from '../../img/matcaps/08.png';
import urlFlag from '../../img/usflag.png';
import urlGlobe from '../../img/xst_globe.png';
import urlTextureTbcd from '../../img/tbcd_bg_2.png';
import urlTextureTbcdBump from '../../img/tbcd_bump.png';
import urlMask from '../../img/coin_mask.png';


export class Coin {
    constructor(name) {
        this.name = name;
        this.group = new THREE.Group();
        this.loadCount = 0;
        this.init();
    }



    init() {
        this.group.scale.set(0, 0, 0);

        this.addCoinBase();

        if (this.name === 'tbcd') {
            this.addBumpLogo();
        } else {
            this.addLogo();
        }

        this.addBackground();
        this.addMatcapMaterial();
    }



    addMatcapMaterial() {
        const urls = {
            xor: urlMatXor,
            val: urlMatVal,
            pswap: urlMatPswap,
            ceres: urlMatCeres,
            deo: urlMatDeo,
            xstusd: urlMatXstusd,
            xst: urlMatXst,
            tbcd: urlMatTbcd
        };

        const matcapTexture = textureLoader.load(
            urls[this.name],
            () => {
                this.loadCount++;
                this.checkIfReady();
            }
        );
        matcapTexture.encoding = THREE.sRGBEncoding;

        this.matcapMaterial = new THREE.MeshMatcapMaterial({
            matcap: matcapTexture
        });
    }



    addCoinBase() {
        gltfLoader.load(urlModelBase, gltf => {
            const g = gltf.scene;

            g.traverse(node => {
                node.material = this.matcapMaterial;
            });

            this.group.add(g);

            this.loadCount++;
            this.checkIfReady();
        });
    }


    addBackground() {
        const opacity = {
            xor: 0.2,
            val: 0.2,
            pswap: 0.2,
            ceres: 0.25,
            deo: 0.25,
            xstusd: 0.4,
            xst: 0.6,
            tbcd: 0.2
        };

        const planeGeometry = new THREE.PlaneBufferGeometry(1.8, 1.8, 1, 1);
        const backMaterial = new THREE.MeshBasicMaterial({
            color: 0x000000,
            transparent: true,
            opacity: opacity[this.name],
            side: THREE.DoubleSide
        });

        if (this.name === 'xstusd') {
            const texture = textureLoader.load(
                urlFlag,
                () => {
                    this.loadCount++;
                    this.checkIfReady();
                }
            );
            texture.encoding = THREE.sRGBEncoding;

            texture.repeat.set(0.98, 0.98);
            texture.center.set(0.5, 0.5);
            backMaterial.map = texture;
            backMaterial.color = new THREE.Color(0xffffff);
        } else if (this.name === 'xst') {
                const texture = textureLoader.load(
                    urlGlobe,
                    () => {
                        this.loadCount++;
                        this.checkIfReady();
                    }
                );
                texture.encoding = THREE.sRGBEncoding;

                texture.repeat.set(1.5, 1.5);
                texture.center.set(0.5, 0.5);
                backMaterial.map = texture;
                backMaterial.color = new THREE.Color(0xffffff);
        } else {
            const mask = textureLoader.load(
                urlMask,
                () => {
                    this.loadCount++;
                    this.checkIfReady();
                }
            );
            backMaterial.alphaMap = mask;
        }

        const plane = new THREE.Mesh(planeGeometry, backMaterial);
        plane.rotation.x = Math.PI * -0.5;
        plane.position.y = 0.075;

        const plane2 = plane.clone();
        plane2.rotation.y = Math.PI;
        plane2.position.y = -0.075;

        this.group.add(plane, plane2);
    }



    addLogo() {
        const urls = {
            xor: urlModelXor,
            val: urlModelVal,
            pswap: urlModelPswap,
            ceres: urlModelCeres,
            deo: urlModelDeo,
            xstusd: urlModelXst,
            xst: urlModelXst
        };

        gltfLoader.load(urls[this.name], gltf => {
            const g = gltf.scene;

            g.traverse(node => {
                node.material = this.matcapMaterial;
            });

            this.group.add(g);

            this.loadCount++;
            this.checkIfReady();
        });
    }



    addBumpLogo() {
        const urlsTexture = {
            tbcd: urlTextureTbcd
        };
        const urlsBump = {
            tbcd: urlTextureTbcdBump
        };

        const planeGeometry = new THREE.PlaneBufferGeometry(1.8, 1.8, 1, 1);
        const texture = textureLoader.load(
            urlsTexture[this.name],
            () => {
                this.loadCount++;
                this.checkIfReady();
            }
        );
        texture.encoding = THREE.sRGBEncoding;

        const bumpMap = textureLoader.load(
            urlsBump[this.name],
            () => {
                this.loadCount++;
                this.checkIfReady();
            }
        );

        const material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            transparent: true,
            map: texture,
            side: THREE.DoubleSide,
            opacity: 0.9,
            bumpMap: bumpMap,
            bumpScale: 1,
        });

        const plane = new THREE.Mesh(planeGeometry, material);
        plane.rotation.x = Math.PI * -0.5;
        plane.position.y = 0.09;

        const plane2 = plane.clone();
        plane2.rotation.y = Math.PI;
        plane2.position.y = -0.09;

        this.group.add(plane, plane2);
    }



    checkIfReady() {
        if (this.loadCount >= 4) {
            this.group.scale.set(1, 1, 1);
        }
    }
}
