/**
 * Disclaimer about using cookie or so
 */

class Disclaimer {
    constructor(el) {
        this.container = el;
        this.checkHistory();
        // localStorage.clear(); // debug for IE and mobile devices
    }


    checkHistory() {
        /**
         * Check if the user has pushed the button
         */
        if (!window.localStorage.disclaimerAgree) {
            this.create();
        }
    }


    create() {
        /**
         * Create block from template
         */
        const html = $(this.container).html();
        document.getElementsByTagName('body')[0].insertAdjacentHTML('beforeend', html);

        this.bind();
    }


    bind() {
        /**
         * Hide block on button click
         */
        document.querySelectorAll('.js-disclaimer-agree').forEach(btn => {
            btn.addEventListener('click', () => this.hide());
        });
    }


    hide() {
        /**
         * Hide block and write it in localStorage
         */
        document.querySelectorAll('.js-disclaimer').forEach(el => {
            el.parentNode.removeChild(el);
        });

        window.localStorage.setItem('disclaimerAgree', true);
    }
}



document.querySelectorAll('.js-disclaimer-template').forEach(el => {
    new Disclaimer(el);
});
